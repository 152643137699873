<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="localData.avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- button -->
        <b-button
          ref="refSelectImage"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ $t('Select Image') }}
        </b-button>
        <b-button
          v-if="imageFile !== null"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="saveChangeImage"
        >
          {{ $t('Save Change Image') }}
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="imageFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="resetImage"
        >
          {{ $t('Reset Image') }}
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        ref="refForm"
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetLocalForm"
      >
        <b-row>
          <!-- email -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              :label="$t('Email')"
              label-for="account-email"
            >

              <validation-provider
                #default="validationContext"
                :name="$t('Email')"
                rules="required|email"
              >
                <b-form-input
                  id="account-email"
                  v-model="localData.email"
                  :placeholder="$t('Email')"
                  :state="getValidationState(validationContext)"
                  :disabled="busy"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>

            </b-form-group>
          </b-col>

          <!-- username -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              :label="$t('Username')"
              label-for="account-username"
            >

              <validation-provider
                #default="validationContext"
                :name="$t('Username')"
                rules="required"
              >
                <b-form-input
                  id="account-username"
                  v-model="localData.username"
                  :placeholder="$t('Username')"
                  :state="getValidationState(validationContext)"
                  :disabled="busy"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- name -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              :label="$t('Full Name')"
              label-for="account-name"
            >
              <b-form-input
                v-model="localData.name"
                name="name"
                :placeholder="$t('Name')"
              />
            </b-form-group>
          </b-col>

          <!-- company -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              :label="$t('Company')"
              label-for="account-company"
            >
              <b-form-input
                v-model="localData.company"
                name="company"
                :placeholder="$t('Company')"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- role -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('User Role')"
              label-for="user-role"
            >
              <v-select
                v-model="localData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('Status')"
              label-for="user-status"
            >
              <v-select
                v-model="localData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>

          <!-- pricing-plan -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('Pricing Plan')"
              label-for="user-pricing-plan"
            >
              <v-select
                v-model="localData.pricing_plan_code"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="localData.pricing_plan_options"
                :reduce="val => val.value"
                :clearable="true"
                input-id="user-pricing-plan"
              />
            </b-form-group>
          </b-col>

          <!-- is_verified -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('Email Verify')"
              label-for="user-verified"
            >
              <b-form-checkbox
                id="user-verified"
                v-model="localData.is_verified"
                name="check-button"
                switch
                inline
              >
                <span>{{ (localData.is_verified === true)? $t('Is Verified') : $t('Not Verify') }}</span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <!-- alert -->
          <b-col
            v-if="localData.is_verified === false"
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <h4 class="alert-heading">
                {{ $t('Your email is not confirmed. Please check your inbox') }}
              </h4>
              <div class="alert-body">
                <b-link class="alert-link">
                  {{ $t('Resend confirmation') }}
                </b-link>
              </div>
            </b-alert>
          </b-col>
          <!--/ alert -->
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-button
              ref="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="busy"
              @click="onSubmit"
            >
              {{ $t('Save changes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetLocalForm"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="text-right d-none d-md-block d-lg-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoUserList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-lg-none d-md-none d-sm-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoUserList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              ref="dialogOk"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
  BOverlay,
  BProgress,
  BFormInvalidFeedback,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { t } from '@/@core/libs/i18n/utils'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BOverlay,
    BProgress,
    BFormInvalidFeedback,
    vSelect,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localData: JSON.parse(JSON.stringify(this.generalData)),
      userData: JSON.parse(localStorage.getItem('userData')),
      imageFile: null,
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      isAction: null,
    }
  },
  mounted() {
    // this.$refs.submit.focus()
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
          this.isAction = 'form'
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.isAction === 'form') {
        store.dispatch('user-store/editUser', { id: this.$router.currentRoute.params.id, data: this.localData })
          .then(response => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
            this.$emit('update-user-edit', response.data)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.isAction === 'file') {
        const formData = new FormData()
        formData.append('file', this.imageFile)

        store.dispatch('user-store/uploadImageUser', { id: this.$router.currentRoute.params.id, data: formData })
          .then(response => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
            this.imageFile = null
            this.$emit('update-user-edit', response.data)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
            // this.imageFile = null
          })
      }
      this.$refs.submit.focus()
    },
    resetLocalForm() {
      this.localData = JSON.parse(JSON.stringify(this.generalData))
      this.$refs.refFormObserver.reset()
      this.$refs.submit.focus()
    },
    resetImage() {
      this.$refs.previewEl.src = this.localData.avatar
      this.imageFile = null
    },
    saveChangeImage() {
      if (this.imageFile !== null) {
        this.processing = false
        this.busy = true
        this.isAction = 'file'
      } else {
        const msg = this.$i18n.t('Please select image file')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
        this.busy = false
        this.processing = false
      }
    },
    gotoUserList() {
      this.$router.push({ name: 'user-list' })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { previewEl.value.src = base64 })

    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const roleOptions = [
      { label: t('Admin'), value: 'admin' },
      { label: t('Staff'), value: 'staff' },
    ]

    const statusOptions = [
      { label: t('Pending'), value: 'pending' },
      { label: t('Active'), value: 'active' },
      { label: t('Inactive'), value: 'inactive' },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,

      roleOptions,
      statusOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
